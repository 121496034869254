import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    { meta: { index: 0 }, path: '/', component: () => import('@/Login/Index'), name: 'index' },
    { meta: { index: 0 }, path: '/error', component: () => import('@/Error/Error'), name: 'error' },
    { meta: { index: 1 }, path: '/login', component: () => import('@/Login/Login'), name: 'login' },
    { meta: { index: 1 }, path: '/loginforadult', component: () => import('@/Login/LoginForAdult'), name: 'loginforadult' },
    { meta: { index: 1 }, path: '/activitys', component: () => import('@/Activity/Activitys'), name: 'activitys' }, // 活动列表
    { meta: { index: 2 }, path: '/activitysrecorddetail', component: () => import('@/Activity/ActivitysRecordDetail'), name: 'activitysrecorddetail' }, // 活动列表
    { meta: { index: 2 }, path: '/actinfo', component: () => import('@/Activity/ActInfo'), name: 'actinfo' }, // 活动明细
    { meta: { index: 3 }, path: '/actform', component: () => import('@/Activity/ActForm'), name: 'actform' }, // 活动表单
    { meta: { index: 4 }, path: '/actpay', component: () => import('@/Activity/ActPay'), name: 'actpay' }, // 活动支付
    { meta: { index: 5 }, path: '/actsuccess', component: () => import('@/Activity/ActPaySuccess'), name: 'actsuccess' }, // 活动支付
    { meta: { index: 2 }, path: '/register', component: () => import('@/Login/Register'), name: 'register' },// 新学员注册
    { meta: { index: 2 }, path: '/registerforadult', component: () => import('@/Login/RegisterForAdult'), name: 'registerforadult' },// 幸福夜校学员注册
    { meta: { index: 3 }, path: '/editpwd', component: () => import('@/Login/EditPwd'), name: 'editpwd' },// 修改密码
    { meta: { index: 2 }, path: '/main', component: () => import('@/Main/Main'), name: 'main' },
    { meta: { index: 3 }, path: '/bdxz', component: () => import('@/Wybm/Bdxz'), name: 'bdxz' }, // 报读须知
    { meta: { index: 4 }, path: '/dzfp', component: () => import('@/Wybm/Dzfp'), name: 'dzfp' }, // 填写电子发票
    { meta: { index: 5 }, path: '/bdsd', component: () => import('@/Wybm/Bdsd'), name: 'bdsd' }, // 选择报读时段
    { meta: { index: 6 }, path: '/xzzy', component: () => import('@/Wybm/Xzzy'), name: 'xzzy' }, // 选择专业
    { meta: { index: 7 }, path: '/xzkc', component: () => import('@/Wybm/Xzkc'), name: 'xzkc' }, // 选择课程
    { meta: { index: 7 }, path: '/xzkcgroup', component: () => import('@/Wybm/XzkcGroup'), name: 'xzkcgroup' }, // 选择课程 少儿社团
    { meta: { index: 8 }, path: '/qrkc', component: () => import('@/Wybm/Qrkc'), name: 'qrkc' }, // 确认课程
    { meta: { index: 9 }, path: '/paysuccess', component: () => import('@/Wybm/PaySuccess'), name: 'paysuccess' }, // 支付结果
    { meta: { index: 9 }, path: '/baodusuccess', component: () => import('@/Wybm/BaoduSuccess'), name: 'baodusuccess' }, // 免费报读 报读结果
    { meta: { index: 10}, path: '/ybdbj', component: () => import('@/Ybmbj/Ybdbj'), name: 'ybdbj' }, // 已报读班级
    { meta: { index: 11}, path: '/refundsuccess', component: () => import('@/Ybmbj/RefundSuccess'), name: 'refundsuccess' }, // 退款成功
    { meta: { index: 11}, path: '/lsbdbj', component: () => import('@/Ybmbj/Lsbdbj'), name: 'lsbdbj' }, // 历史报读班级
    { meta: { index: 11}, path: '/dykclb', component: () => import('@/Ybmbj/Dykclb'), name: 'dykclb' }, // 打印课程列表
    { meta: { index: 11}, path: '/zlxg', component: () => import('@/My/Zlxg'), name: 'zlxg' }, // 资料修改
    { meta: { index: 11}, path: '/jszlxg', component: () => import('@/My/JSZlxg'), name: 'jszlxg' }, // 资料修改
    { meta: { index: 12}, path: '/sczp', component: () => import('@/My/Sczp'), name: 'sczp' }, // 上传照片
    { meta: { index: 11}, path: '/tkcx', component: () => import('@/Tkcx/Tkcx'), name: 'tkcx' }, // 退款查询
    { meta: { index: 11}, path: '/xgmm', component: () => import('@/Xgmm/Xgmm'), name: 'xgmm' }, // 修改密码
	{ meta: { index: 11}, path: '/hdgl', component: () => import('@/Hdgl/Hdgl'), name: 'hdgl' }, // 活动管理
	{ meta: { index: 11}, path: '/hdgljj', component: () => import('@/Hdgl/Hdgljj'), name: 'hdgljj' }, // 活动管理-简介
	{ meta: { index: 11}, path: '/hdglmx', component: () => import('@/Hdgl/Hdglmx'), name: 'hdglmx' }, // 活动管理-明细项
	{ meta: { index: 12}, path: '/jxjh', component: () => import('@/Jxjh/Jxjh'), name: 'jxjh' }, // 教学计划
	{ meta: { index: 12}, path: '/jxjhtx', component: () => import('@/Jxjh/Jxjhtx'), name: 'jxjhtx' }, // 教学计划填写
	{ meta: { index: 12}, path: '/qjsq', component: () => import('@/Qjsq/Qjsq'), name: 'qjsq' }, // 教师请假
	{ meta: { index: 12}, path: '/szqj', component: () => import('@/Qjsq/Szqj'), name: 'szqj' }, // 新增请假
    { meta: { index: 12}, path: '/rkjh', component: () => import('@/Rkjh/Rkjh'), name: 'rkjh' }, // 教学计划
    { meta: { index: 12}, path: '/rkjhedit', component: () => import('@/Rkjh/RkjhEdit'), name: 'rkjhedit' }, // 教学计划
    { meta: { index: 12}, path: '/bmqk', component: () => import('@/Bmqk/Bmqk'), name: 'bmqk' }, // 报名情况
    { meta: { index: 4}, path: '/tktz', component: () => import('@/Tktz/Tktz'), name: 'tktz' }, // 停课通知
    { meta: { index: 5}, path: '/tktzedit', component: () => import('@/Tktz/TktzEdit'), name: 'tktzedit' }, // 停课通知修改
    { meta: { index: 6}, path: '/tktzsendmsghistory', component: () => import('@/Tktz/TktzSendMsgHistory'), name: 'tktzsendmsghistory' }, // 停课通知修改


    { meta: { index: 0 }, path: '/vloading', component: () => import('@/Volunteer/Login/Loading'), name: 'vloading' },// 志愿者Loading
    { meta: { index: 1 }, path: '/vlogin', component: () => import('@/Volunteer/Login/Login'), name: 'vlogin' },// 志愿者登录
    { meta: { index: 2 }, path: '/vregisterletter', component: () => import('@/Volunteer/Login/RegisterLetter'), name: 'vregisterletter' },// 承诺书
    { meta: { index: 3 }, path: '/vregister', component: () => import('@/Volunteer/Login/Register'), name: 'vregister' },// 注册
    { meta: { index: 2 }, path: '/vmain', component: () => import('@/Volunteer/Main/Main'), name: 'vmain' },// 志愿者主页

    { meta: { index: 3 }, path: '/cjhdlist', component: () => import('@/Volunteer/Cjhd/CjhdList'), name: 'cjhdlist' },// 参加活动列表
    { meta: { index: 4 }, path: '/cjhd', component: () => import('@/Volunteer/Cjhd/Cjhd'), name: 'cjhd' },// 参加活动
    { meta: { index: 4 }, path: '/cjhdsubmit', component: () => import('@/Volunteer/Cjhd/CjhdSubmit'), name: 'cjhdsubmit' },// 参加活动提交
    { meta: { index: 4 }, path: '/cjhdsuccess', component: () => import('@/Volunteer/Cjhd/CjhdSuccess'), name: 'cjhdsuccess' },// 活动提交成功

    { meta: { index: 3 }, path: '/vhdgl', component: () => import('@/Volunteer/Hdjl/Hdjl'), name: 'vhdgl' },// 活动记录列表
    { meta: { index: 4 }, path: '/vhdgledit', component: () => import('@/Volunteer/Hdjl/HdjlEdit'), name: 'vhdgledit' },// 活动记录

    { meta: { index: 3 }, path: '/vxxtdlist', component: () => import('@/Volunteer/Xxtd/XxtdList'), name: 'vxxtdlist' },// 学习天地列表
    { meta: { index: 4 }, path: '/vxxtd', component: () => import('@/Volunteer/Xxtd/Xxtd'), name: 'vxxtd' },// 学习天地

    { meta: { index: 3 }, path: '/vhdxd', component: () => import('@/Volunteer/Hdxd/Hdxd'), name: 'vhdxd' },// 活动心得列表
    { meta: { index: 4 }, path: '/vhdxddit', component: () => import('@/Volunteer/Hdxd/HdxdEdit'), name: 'vhdxdedit' },// 活动心得

    { meta: { index: 3 }, path: '/vpyqlist', component: () => import('@/Volunteer/Pyq/PyqList'), name: 'vpyqlist' },// 朋友圈
    { meta: { index: 3 }, path: '/pyqpost', component: () => import('@/Volunteer/Pyq/PyqPost'), name: 'pyqpost' },// 发送朋友圈

    { meta: { index: 3 }, path: '/vzlxg', component: () => import('@/Volunteer/Zlxg/Zlxg'), name: 'vzlxg' },// 编辑志愿者信息
    { meta: { index: 3 }, path: '/veditpwd', component: () => import('@/Volunteer/Login/EditPwd'), name: 'veditpwd' },// 修改密码




]

export default new VueRouter({
    routes
})
