var theme = 'white'
var key_loginType = "loginType"
var LOGINTYPE_OLD = 1; // 老人大学
var LOGINTYPE_CHILD = 2; // 少儿
var LOGINTYPE_ACTIVITY = 3; // 活动


function isMobile() {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
}

export default {
    theme,
    key_loginType,
    LOGINTYPE_OLD,
    LOGINTYPE_CHILD,
    LOGINTYPE_ACTIVITY,
    isMobile
}
