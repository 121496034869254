<template>
  <div id="app" :style="{ width: this.width }">
    <!--<transition :name="transitionName">
      <router-view :key="$route.fullPath"></router-view>
    </transition>-->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
  import EduConstants from "@/api/EduConstants";
  import WechatApi from '@/api/wechat'
  import wx from 'weixin-js-sdk'
  export default {
    name: 'App',
    components: {},
    data() {
      return {
        transitionName: '',
        width: '100%'
      }
    },
    watch: {//使用watch 监听$router的变化
      $route(to, from) {
        if (to.meta.index == 0 && from.meta.index == 0) {
          return
        }
        if (to.meta.index > from.meta.index) {
          //设置动画名称
          this.transitionName = 'slide-left';
        } else {
          this.transitionName = 'slide-right';
        }
      }
    },
    methods: {
      isMobile() {
        return navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        );
      },setShareMsg() {
        WechatApi.getShareConfig().then(response => {
          var data = response.res
          // console.log(JSON.stringify(data))
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，企业号的唯一标识，此处填写企业号corpid
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名，见附录1
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", "onMenuShareTimeline", "onMenuShareAppMessage"] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })
          // wx.ready(function(){
          // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
          this.setShareConfig()

          // });
        })
      },
      setShareConfig() {
        // alert('测试分享。')
        var stitle = '测试'

        wx.updateAppMessageShareData({
          title: stitle, // 分享标题
          desc: '省妇儿活动中心教学管理系统', // 分享描述
          link: window.location.href, // 'https://edu.gdfezx.com/qxapi/edu/activity/activitys/1', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
          success: function () {
            // 设置成功
            // alert('分享给朋友成功。')
          },
          cancel: function() {
            // alert('cancel');
          },
          fail: function(res) {
            // alert('fail'+JSON.stringify(res));
            console.log(res)
          }
        })

        wx.updateTimelineShareData({
          title: stitle, // 分享标题
          link: window.location.href, // 'https://edu.gdfezx.com/qxapi/edu/activity/activitys/1',// window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: 'http://edumgr.gdfezx.com/img/logo.73f12c01.png', // 分享图标
          success: function () {
            // 设置成功
            // alert('分享到朋友圈成功。')
          },
          cancel: function() {
            // alert('cancel');
          },
          fail: function(res) {
            // alert('fail'+JSON.stringify(res));
            console.log(res)
          }
        })
      }
    },
    mounted() {
      this.setShareMsg()
      if (EduConstants.isMobile()) {
        console.log('mobile');
        // 移动端
        this.width = '100%'
      } else {
        console.log('pc');
        // pc端
        this.width = '500px'
      }
    }
  }
</script>

<style>
  body, html {
    margin: 0;
    border: 0;
  }

  #app {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    border: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active {
    will-change: transform;
    transition: all 300ms;
    position: absolute;
  }

  .slide-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .slide-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  .slide-left-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  .slide-left-leave-active {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .edu-frame {
    width: 100%;
    height: 100vh;
    background-color: #F7F7F7;
    position: relative;
    overflow: auto;
  }
  .edu-group {
    width: 95%;
    margin: 0 auto;
    padding: 8px 0px;
    background-color: white;
    border: 0px solid #dddddd;
    border-radius: 8px;
    box-shadow: 1px 2px 2px #ddd;
  }
  .step-tips {
    font-weight: bold;
    margin-left: 13px;
  }

  .step-tips-detail {
    font-size: 13px;
    color: red;
    width: 96%;
    margin: 10px auto 0px;
    width: calc(100% - 26px);
  }
  .content-frame {
    width: 100%;
    height: calc(100% - 46px - 44px);
    position: relative;
    overflow: auto;
  }
  .bottom-frame {
    width: 100%;
    height: 44px;
    display: flex;
  }
  .class-item {
    width: calc(95% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .class-detail {
    flex: 1;
    font-size: 14px;
    line-height: 25px;
  }
  .class-detail-title {
    font-weight: bold;
    font-size: 16px;
    display: flex;
  }
  .class-detail-title-name {
    flex: 1;
  }
  .class-detail-row {
    display: flex;
  }
  .class-detail-row-title {
    flex: 1;
  }
  .txt-price {
    color: orangered;
  }
  .class-form {
    width: 95%; margin: 0 auto;
  }
  pre {
    white-space: pre-wrap; /*css-3*/
    white-space: -moz-pre-wrap; /*Mozilla,since1999*/
    white-space: -o-pre-wrap; /*Opera7*/
    word-wrap: break-word; /*InternetExplorer5.5+*/
  }
  /*.van-grid-item__content--center {
    padding: 3px 3px !important;
  }*/
</style>
