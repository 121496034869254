import EduConstants from "./EduConstants";
import ImageCompressor from 'image-compressor.js';
function uploadFileByBase64(content, func) {
    var reader = new FileReader()
    reader.readAsDataURL(content)
    var img = new Image
    reader.onload = function() {
        // https://www.jianshu.com/p/e63d6e898522
        var width = 600, //图像大小
            quality = 0.9, //图像质量
            canvas = document.createElement("canvas"),
            drawer = canvas.getContext("2d");
        img.src = this.result;
        img.onload = function () {
            canvas.width = width;
            canvas.height = width * (img.height / img.width);
            /* canvas.width = img.width;
            canvas.height = img.height; */
            drawer.drawImage(img, 0, 0, canvas.width, canvas.height);
            var base64res = canvas.toDataURL("image/jpeg", quality);
            if (func != null && func != undefined) {
                func(base64res)
            }
        }
    }
}


function getBase64(url, callback) {
    var Img = new Image(),
        dataURL = '';
    Img.src = url;
    Img.setAttribute('crossOrigin', 'Anonymous');
    Img.onload = function() {
        var canvas = document.createElement('canvas'),
            width = Img.width,
            height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
        dataURL = canvas.toDataURL('image/jpeg');
        return callback ? callback(dataURL) : null;
    };
}


function compressFile(file) {
    return new Promise((resolve, reject) => {
        const options = {
            success(result) {
                // 将压缩后的 Blob 转换为 File 对象（如果组件支持Blob则不用这一步）
                const compressedFile = new File([result], file.name, {
                    type: file.type,
                    lastModified: Date.now(),
                });
                resolve(compressedFile);
            },
            error(e) {
                reject(e);
            },
        };
        if (file.size > 5 * 1024 * 1024) {
            options.quality = 0.6; // 压缩质量
            options.convertSize = false;//不进行图像尺寸的调整
            options.checkOrientation = false; // 图片翻转，默认为false
        }
        new ImageCompressor(file, options);
    });
}

function addSession(key, value){
    sessionStorage.setItem(key, value)
}
function getSession(key) {
    return sessionStorage.getItem(key)
}

function getLoginType() {
    return getSession(EduConstants.key_loginType)
}

function getCurCust() {
    var sessionCust = getSession("cust");

    if (sessionCust != null) {
        sessionCust = JSON.parse(sessionCust)

        sessionCust.loginType = getLoginType()
    }
    return sessionCust
}

function setCurCust(cust) {
    return addSession("cust", JSON.stringify(cust));
}

function setToken(token) {
    return addSession("token", token);
}

function getToken() {
    return getSession('token')
}

function setIdentityType(identityType) {
    return addSession("identityType", identityType);
}

function getIdentityType() {
    return getSession('identityType')
}

export default {
    getBase64,
    compressFile,
    uploadFileByBase64,
    addSession,
    getSession,
    getLoginType,
    getCurCust,
    setCurCust,
    setToken,
    getToken,
    setIdentityType,
    getIdentityType
}
