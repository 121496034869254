import Vue from 'vue'
import Vuex from 'vuex'
import EduConstants from "@/api/EduConstants";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        theme: EduConstants.theme,
        curTab: 0, // 主页当前Tab
    },
    mutations: {
        // 更改主题
        changeTheme(state, theme) {
            console.log('theme == ' + theme)
            state.theme = theme
        },
        // 更换tab页
        changeTab(state, tab) {
            state.curTab = tab
        }
    },
    actions: {
        // 更换tab页
        changeTab({commit}, tab) {
            commit('changeTab', tab)
        },
        clear({commit}) {
            // 清空数据
            console.log('清空数据store')
            commit('changeTab', 0)
        }
    }
})
